import React, { useEffect, useState } from "react";

const Footer = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className="bg-custom-blue text-white py-4 relative">
      <div className="container mx-auto flex flex-col items-center">
        <div className="text-center">
          <p>
            &copy; {new Date().getFullYear()} Game Live. All Rights Reserved.
          </p>
        </div>
        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 bg-blue-600 text-white rounded-full p-3 shadow-lg hover:bg-blue-700 transition-colors"
            aria-label="Scroll to top"
          >
            ↑
          </button>
        )}
      </div>
    </footer>
  );
};

export default Footer;
