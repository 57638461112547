const About = () => (
  <main className="p-4 my-10">
    <div className="container mx-auto">
      <div>
        <h1 className="text-3xl font-bold mb-4">About Us</h1>
        <p className="text-gray-700">Welcome to HTML5Game.Live!</p>
        <p>
          At HTML5Game.Live, we are dedicated to providing a dynamic and
          engaging platform where gamers can discover, play, and enjoy the best
          HTML5 games available. Our mission is to create a seamless gaming
          experience that is accessible anytime, anywhere, on any device.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Who We Are</h2>
        <p>
          We are a team of passionate gamers, developers, and designers who
          believe in the power of HTML5 technology to transform the gaming
          landscape. With our combined expertise, we aim to bring you a diverse
          selection of high-quality games that cater to all types of players.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">What We Offer</h2>
        <ul class="list-disc list-inside	">
          <li>
            Extensive Game Library: Explore our vast collection of HTML5 games
            across various genres, including action, adventure, puzzle,
            strategy, and more. Whether you're looking for a quick game to pass
            the time or an immersive adventure, you'll find it here.
          </li>
          <li>
            Quality Assurance: Every game on our platform is carefully curated
            and tested to ensure it meets our high standards of quality and
            performance. We strive to provide only the best gaming experiences
            for our users.
          </li>
          <li>
            Seamless Experience: Enjoy instant access to all our games directly
            in your web browser. No downloads or installations are required, so
            you can start playing immediately.
          </li>
          <li>
            Community Engagement: Join a vibrant community of gamers where you
            can share your experiences, compete in leaderboards, and connect
            with others who share your passion for gaming.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Vision</h2>
        <p>
          Our vision at HTML5Game.Live is to become the go-to destination for
          HTML5 game enthusiasts worldwide. We aim to foster a community of
          gamers who can discover new favorites, share their love for gaming,
          and enjoy a seamless and enjoyable gaming experience.
        </p>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Our Commitment</h2>
        <ul class="list-disc list-inside	">
          <li>
            Innovation: We continuously seek to innovate and enhance our
            platform, ensuring you have access to the latest and greatest HTML5
            games.
          </li>
          <li>
            Quality: We are committed to providing a high-quality gaming
            experience by carefully selecting and testing every game on our
            platform.
          </li>
          <li>
            Community: We value our community and strive to create a welcoming
            and inclusive environment for all gamers.
          </li>
          <li>
            Transparency: We believe in maintaining transparency in all our
            operations, from game listings to customer interactions.
          </li>
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="font-bold">Get In Touch</h2>
        <p>
          We love hearing from our community! If you have any questions,
          feedback, or just want to chat about gaming, feel free to reach out to
          us at contact@html5game.live.
        </p>
        <p>Thank you for visiting HTML5Game.Live. Happy gaming!</p>
      </div>
    </div>
  </main>
);
export default About;
