import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/4 (1).png";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const smoothScrollToTop = (duration) => {
    const start = window.scrollY;
    const startTime = performance.now();

    const scroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      window.scrollTo(0, start * (1 - progress));
      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const scrollToTop = () => {
    smoothScrollToTop(1000); // Adjust duration as needed (1000 ms = 1 second)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to determine if a link is active
  const isActive = (path) =>
    currentPath === path ? "text-blue-400 underline" : "";

  const navigate = useNavigate();

  return (
    <>
      <header className="bg-custom-blue text-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <img
            src={logo}
            alt=""
            className="w-[150px]"
            onClick={() => navigate("/")}
          />
          <nav className="flex space-x-4">
            <Link
              to="/about"
              className={`hover:underline ${isActive("/about")}`}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className={`hover:underline ${isActive("/contact")}`}
            >
              Contact Us
            </Link>
            <Link
              to="/term&condition"
              className={`hover:underline ${isActive("/term&condition")}`}
            >
              Terms and Conditions
            </Link>
            <Link
              to="/privacy"
              className={`hover:underline ${isActive("/privacy")}`}
            >
              Privacy Policy
            </Link>
          </nav>
        </div>
      </header>
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-custom-blue text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors z-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 15l-7-7-7 7" />
          </svg>
        </button>
      )}
    </>
  );
};

export default Header;
