import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Const/Games";

const getRandomGames = (games, count) => {
  const shuffled = [...games].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const getRandomHexColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const truncateDescription = (description, minLength = 50, maxLength = 100) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = description;
  const textContent = tempElement.textContent || tempElement.innerText || "";

  if (textContent.length > minLength) {
    const truncatedText = textContent.slice(0, maxLength);
    const truncatedElement = document.createElement("div");
    truncatedElement.innerHTML =
      truncatedText + (textContent.length > maxLength ? "..." : "");
    return truncatedElement.innerHTML;
  } else {
    return textContent;
  }
};

const HomePage = () => {
  const [selectedGames, setSelectedGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const games = getRandomGames(Games, 12);
    setTimeout(() => {
      setSelectedGames(games);
      setLoading(false);
    }, 800); // Simulate a loading delay
  }, []);

  return (
    <section className="p-8 bg-gray-100 min-h-screen flex items-center justify-center">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-7xl">
          {selectedGames.map((game, index) => {
            const color = getRandomHexColor();

            return (
              <div
                key={index}
                className={`bg-white rounded-lg shadow-lg border-2 transition-transform transform hover:scale-105 relative overflow-hidden`}
                style={{ borderColor: color }}
              >
                <img
                  src={game.image}
                  alt={game.title}
                  className="w-full h-64 object-cover rounded-t-lg"
                />
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800">
                    {game.title}
                  </h2>
                  <p
                    className="text-base text-gray-600 mb-6"
                    dangerouslySetInnerHTML={{
                      __html: truncateDescription(game.description),
                    }}
                  />
                </div>
                <Link
                  to={`/play?play=${game?.gameurl}`}
                  className="absolute bottom-4 right-4 text-center rounded-full text-white font-semibold shadow-md hover:shadow-lg transition-shadow duration-300 px-4 py-2"
                  style={{
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: "2px",
                  }}
                >
                  <svg
                    className="w-6 h-6 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 3l14 9-14 9V3z"></path>
                  </svg>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default HomePage;
