const TermAndCondition = () => (
  <main className="p-4 my-10">
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="text-gray-700">Last Updated: 20 July 2024</p>
      <p>
        Welcome to HTML5Game.Live! These terms and conditions outline the rules
        and regulations for the use of our website and services. By accessing
        and using HTML5Game.Live, you agree to comply with and be bound by these
        terms and conditions. If you do not agree with any part of these terms,
        you should not use our website.
      </p>
      <div className="pt-4">
        <ol class="list-decimal list-inside">
          <li className="font-bold">Use of the Website</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Eligibility: By using our site, you affirm that you are at least
              13 years old. If you are under 13, you must have parental
              permission to use our services.
            </li>
            <li>
              License: HTML5Game.Live and/or its licensors own the intellectual
              property rights for all material on HTML5Game.Live. All
              intellectual property rights are reserved. You may view and/or
              print pages from HTML5Game.Live for your own personal use, subject
              to restrictions set in these terms and conditions.
            </li>
            <li>Prohibited Uses: You are prohibited from:</li>
            <li> Republishing material from HTML5Game.Live.</li>
            <li>
              Selling, renting, or sub-licensing material from HTML5Game.Live.
            </li>
            <li>
              Reproducing, duplicating, or copying material from HTML5Game.Live.
            </li>
            <li>
              Redistributing content from HTML5Game.Live (unless content is
              specifically made for redistribution).
            </li>
          </ul>
          <li className="font-bold pt-2">User Accounts</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Account Responsibility: If you create an account on
              HTML5Game.Live, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer. You agree to accept responsibility for
              all activities that occur under your account or password.
            </li>
            <li>
              Accuracy of Information: You agree to provide accurate and
              complete information when creating your account and to keep this
              information updated.
            </li>
          </ul>

          <li className="font-bold pt-2">Purchases and Payments </li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Pricing: All prices listed on our website are subject to change
              without notice. We strive to ensure that all pricing information
              is accurate, but errors may occur. If we discover an error in the
              price of any product you have ordered, we will inform you as soon
              as possible and offer you the option to reconfirm your order at
              the correct price or cancel it.
            </li>
            <li>
              Payment Methods: We accept various payment methods, including
              credit/debit cards and PayPal. By providing payment information,
              you represent and warrant that the information is accurate and
              that you are authorized to use the payment method provided.
            </li>
          </ul>

          <li className="font-bold pt-2">Delivery and Access</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Digital Products: Purchased digital products will be delivered
              electronically to the email address associated with your account
              or made available for download from your account page.
            </li>
            <li>
              Service Access: We strive to ensure that our website and services
              are available at all times. However, we do not guarantee
              uninterrupted access and may suspend access for maintenance or
              other reasons.
            </li>
          </ul>
          <li className="font-bold pt-2">Returns and Refunds</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              Digital Products: All sales of digital products are final. We do
              not offer refunds or exchanges for digital products unless
              required by law.
            </li>
            <li>
              Physical Products: Returns and refunds for physical products are
              subject to our Return Policy, which is available on our website.
            </li>
          </ul>
          <li className="font-bold pt-2">Disclaimers</li>
          <ul class="list-disc list-inside pl-4">
            <li>
              No Warranties: HTML5Game.Live is provided "as is" without any
              representations or warranties, express or implied. We make no
              representations or warranties in relation to this website or the
              information and materials provided on this website.
            </li>
            <li>
              Limitations of Liability: In no event shall HTML5Game.Live, nor
              any of its officers, directors, and employees, be liable to you
              for anything arising out of or in any way connected with your use
              of this website, whether such liability is under contract, tort,
              or otherwise. HTML5Game.Live, including its officers, directors,
              and employees, shall not be liable for any indirect,
              consequential, or special liability arising out of or in any way
              related to your use of this website.
            </li>
          </ul>
          <li className="font-bold pt-2">Indemnification</li>
          <p>
            You agree to indemnify and hold harmless HTML5Game.Live and its
            affiliates, officers, directors, employees, and agents from and
            against any and all claims, liabilities, damages, losses, and
            expenses (including reasonable attorneys' fees) arising out of or in
            connection with your use of the website or violation of these terms.
          </p>
          <li className="font-bold pt-2">Governing Law</li>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of India. Any disputes arising out of or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts located in India.
          </p>
          <li className="font-bold pt-2">
            Changes to These Terms and Conditions
          </li>
          <p>
            We reserve the right to amend these terms and conditions at any
            time. Any changes will be posted on this page with an updated
            revision date. Your continued use of the website following the
            posting of changes constitutes your acceptance of such changes.
          </p>
          <li className="font-bold pt-2">Contact Information</li>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at contact@html5game.live.
          </p>
        </ol>
      </div>
    </div>
  </main>
);

export default TermAndCondition;
